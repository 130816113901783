import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { User } from "models";
import { useStore } from "stores";

import Modal from "./Modal";
import Button from "components/Button";
import Multiselect, { MultiselectOption } from "components/inputs/Multiselect";
import LoadingIndicator from "components/LoadingIndicator";

import { useToast } from "components/toast/context";

type AddMembersInput = {
  selectedUsers: MultiselectOption[];
};

type AddMemberModalProps = {
  projectId: string;
  currentMemberIds?: string[];
  onClose: () => void;
  showModal?: boolean;
};

export default function AddMemberModal({
  projectId,
  currentMemberIds = [],
  showModal,
  onClose,
}: AddMemberModalProps) {
  const { userStore, projectStore } = useStore();

  const { showToastVariant } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<MultiselectOption[]>([]);

  const { control, handleSubmit, reset, watch } = useForm<AddMembersInput>({
    defaultValues: { selectedUsers: [] },
  });

  const selectedUsers = watch("selectedUsers");

  const handleAddMember: SubmitHandler<AddMembersInput> = async ({
    selectedUsers,
  }) => {
    setLoading(true);
    // ***: Some users doesn't have mahusai emails so will use primaryEmail instead
    const usersEmails = selectedUsers.map(
      (option) => option?.user?.mahusaiEmail || option.user?.primaryEmail || ""
    );

    const response = await projectStore.addProjectMembers(
      projectId,
      usersEmails
    );

    if (response.ok) {
      onClose();
      reset();
      showToastVariant({
        variant: "success",
        title: "Success",
        subtitle: "Members successfully added to the project",
      });
    } else {
      showToastVariant({
        variant: "error",
        title: "Error",
        subtitle: "Unable to add members to the project",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const { results } = await userStore.listUsers();

      const nextOptions: MultiselectOption[] = (results ?? [])
        .map((user: User) => {
          return {
            user,
            label: user.fullName,
            otherfilterLabels: [user.nickname],
            key: user._id,
            sublabel: user.mahusaiEmail,
            icon: user.avatar,
          };
        })
        .filter((option) => !currentMemberIds.includes(option.key));
      setOptions(nextOptions);
    })();

    // eslint-disable-next-line
  }, [currentMemberIds.length, userStore.listUsers]);

  const currentSelectionCount: number = selectedUsers.length;
  const buttonDisabled: boolean = !currentSelectionCount;

  return (
    <div>
      {showModal && (
        <Modal
          disabled={loading}
          handleClose={() => {
            if (loading) return;
            onClose();
          }}
          stickToBottom={true}
          title="Add Member"
          titleClassName="md:rounded-t-2xl"
        >
          <div className="p-4">
            <p className="color text-sm font-normal text-dark-900/70">
              Search members below to add them to the project
            </p>
            <br />
            <p className="color text-sm font-normal text-dark-900/70">
              Note: If the user doesn't have a{" "}
              <span className="font-semibold">Mahusai-associated email</span>,
              there will be{" "}
              <span className="font-semibold">
                no Google Drive folder shared with the selected user
              </span>
              .
            </p>
          </div>

          <div className="mx-4">
            <Controller
              name="selectedUsers"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label="Search people to add"
                  className="h-14 max-h-36 overflow-y-auto"
                  optionsListClassName="md:min-h-[9rem]"
                  addIconClassName="top-4"
                  onChange={onChange}
                  options={options}
                  value={value}
                  isOptionsWithIcon
                  isShowLabelAtTop={false}
                />
              )}
            />
          </div>

          <hr className="my-4" />

          <div className="mx-4 mb-4 flex flex-row justify-between">
            <Button
              disabled={loading}
              category="primaryOutline"
              className="flex w-[49%] cursor-pointer items-center px-7 py-2 text-primary-900"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              category="primary"
              disabled={loading || buttonDisabled}
              className={twMerge(
                "flex w-[49%] items-center px-7 py-2 text-white"
              )}
              onClick={handleSubmit(handleAddMember)}
            >
              {currentSelectionCount > 1 ? "Add members" : "Add member"}
              {loading && <LoadingIndicator />}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
