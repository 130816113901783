import { User } from "models";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  user: User | undefined;
  width: string;
  fontSize: string;
  className?: string;
};

export function EmptyAvatar({ user, width, fontSize, className }: Props) {
  const firstLast = user?.fullName
    ? user.firstName?.substring(0, 1) + user.lastName?.substring(0, 1)
    : "";

  return (
    <div
      className={twMerge(
        `${width} ${fontSize} flex aspect-square select-none items-center justify-center rounded-full bg-gray-200 text-center font-semibold`,
        className
      )}
    >
      {firstLast}
    </div>
  );
}

export default function Avatar({ user, width, fontSize, className }: Props) {
  const [showEmptyAvatar, setShowEmptyAvatar] = useState<boolean>(
    !user || !user.avatar
  );
  if (showEmptyAvatar) {
    return (
      <EmptyAvatar
        user={user}
        width={width}
        fontSize={fontSize}
        className={className}
      />
    );
  }
  return (
    <img
      src={user?.avatar}
      alt={user?._id}
      className={`${width} aspect-square rounded-full object-cover ${
        className || ""
      }`}
      onError={() => {
        setShowEmptyAvatar(true);
      }}
    />
  );
}
